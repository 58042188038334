<template>
    <div
        class="lni-c-carousel__wrapper lni-u-flex lni-u-full-width lni-u-full-height lni-u-overflow-hidden lni-u-relative"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
    >
        <h2 class="lni-u-visually-hidden">
            Featured links
        </h2>

        <div
            class="lni-u-visually-hidden"
            aria-live="polite"
            aria-atomic="true"
        >
            {{ currentSlide }} of {{ slideCount }}
        </div>

        <button
            class="lni-c-play-pause lni-u-absolute lni-u-button-reset lni-u-pa1 lni-u-screen-only"
            @click="togglePause"
        >
            <span class="lni-u-visually-hidden">{{ paused ? 'Play' : 'Pause' }}</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
            >

                <template v-if="paused">
                    <polygon points="5 3 19 12 5 21 5 3" />
                </template>

                <template v-else>
                    <rect
                        x="3"
                        y="4"
                        width="6"
                        height="16"
                    />
                    <rect
                        x="12"
                        y="4"
                        width="6"
                        height="16"
                    />
                </template>
            </svg>
        </button>

        <div
            class="lni-c-slides lni-u-full-width lni-u-full-height lni-u-overflow-hidden"
            @focusin="focusin"
            @focusout="focusout"
        >
            <transition :name="direction === 'forward' ? 'carousel-slide-from-right' : 'carousel-slide-from-left'">
                <div
                    :key="currentSlide"
                    class="lni-c-slide lni-u-absolute lni-u-full-width lni-u-full-height lni-u-ph3"
                >
                    <a
                        class="lni-c-slide__link lni-u-flex lni-u-justify-between lni-u-flex-column lni-u-full-width lni-u-full-height"
                        :href="carousel[currentSlide].route"
                    >
                        <span class="lni-c-slide__title">{{ carousel[currentSlide].title }}</span>
                        <h3 class="lni-c-slide__headline lni-u-full-width lni-u-ma0">{{ carousel[currentSlide].headline }}</h3>
                        <div
                            class="lni-c-slide__more lni-u-text--all-caps lni-u-type--extra-bold lni-u-self-end"
                            aria-hidden="true"
                        >
                            {{ carousel[currentSlide].callToActionButton }}
                            <span
                                class="lni-c-slide__icon lnicon--triangle--right"
                                aria-hidden="true"
                            />
                        </div>
                    </a>
                </div>
            </transition>
        </div>

        <ul class="lni-c-carousel__pagination lni-u-absolute lni-u-flex lni-u-justify-center lni-u-screen-only">
            <li
                v-for="(slide, index) in carousel"
                :key="slide.id"
            >
                <button
                    :class="['lni-c-carousel__toggle',
                             {'--active': index == currentSlide}]"
                    :aria-label="`slide ${index}`"
                    @click="selectSlide(index)"
                />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'LniCarousel',
    props: {
        carousel: Array,
    },
    data() {
        return {
            currentSlide: 0,
            playpause: null,
            playing: true,
            focused: false,
            paused: true,
            direction: 'forward',
        };
    },
    computed: {
        slideCount() {
            return this.carousel.length;
        },
    },
    methods: {
        selectSlide(index) {
            if ( this.currentSlide > index ) {
                this.direction = 'backward';
            } else {
                this.direction = 'forward';
            }
            this.currentSlide = index;
        },
        start() {
            this.playing = true;
            this.playpause = setInterval(() => {
                this.currentSlide = this.currentSlide + 1 < this.carousel.length
                    ? this.currentSlide + 1 : 0;
                this.direction = 'forward';
            }, this.interval);
        },
        stop() {
            this.playing = false;
            clearInterval(this.playpause);
        },
        togglePause() {
            if ( this.paused ) {
                this.paused = false;
            } else {
                this.paused = true;
                this.stop();
            }
        },
        mouseover() {
            this.stop();
        },
        mouseleave() {
            if ( !this.paused && !this.focused) {
                this.start();
            }
        },
        focusin() {
            this.focused = true;
            this.stop();
        },
        focusout() {
            this.focused = false;
            if ( !this.paused ) {
                this.start();
            }
        },
    },
}; </script>
<style lang="scss">
@import '@gov.wa.lni/application.public-website/source/components/lni-carousel/lni-carousel.scss';
</style>