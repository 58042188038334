<template>
    <div class="lni-c-link-list lni-u-mt2">
        <div
            :class="[ 'lni-c-link-list-header',
                      {'--link': header.route},
                      'lni-u-flex',
                      {'lni-u-pa2': !header.route},
                      'lni-u-justify-center',
                      'lni-u-items-center']">
            <a
                v-if="header.route"
                :href="header.route"
                :class="[ 'lni-c-link-list-header-link',
                          'lni-u-pa2',
                          'lni-u-full-width',
                          'lni-u-full-height',
                          'lni-u-items-center',
                          'lni-u-justify-center',
                          'lni-u-flex']">
                <span
                    v-if="header.icon"
                    :aria-hidden="true"
                    :class="[ 'lni-c-link-list-icon',
                              'lni-u-mr2',
                              `lnicon--${header.icon}`]"></span>
                <span
                    :class="[ 'lni-u-text--all-caps',
                              'lni-u-type--extra-bold',
                              'lni-u-self-center']">{{ header.title }}</span>
                <span
                    :aria-hidden="true"
                    :class="['lni-c-link-list-header-icon',
                             'lni-u-mr2',
                             'lnicon--triangle--right']"></span>
            </a>
            <div
                v-else
                :class="[ 'lni-c-link-list-header-link',
                          'lni-u-full-width',
                          'lni-u-items-center',
                          'lni-u-justify-center',
                          'lni-u-flex']">
                <span
                    v-if="header.icon"
                    :aria-hidden="true"
                    :class="[ 'lni-c-link-list-icon',
                              'lni-u-mr2',
                              `lnicon--${header.icon}`]"></span>
                <span
                    :class="[ 'lni-u-text--all-caps',
                              'lni-u-type--extra-bold',
                              'lni-u-self-center']">{{ header.title }}</span>
            </div>
        </div>
        <ul
            :class="[ 'lni-u-flex',
                      'lni-u-flex-column',
                      'lni-u-pa2']">
            <li
                v-for="(link) in links"
                :key="link.label"
                :class="[ 'lni-c-link-list-item',
                          'lni-u-list-reset',
                          'lni-u-pv1',
                          'lni-u-ml0']">
                <a
                    :href="link.route"
                    :class="[ 'lni-c-link-list-link',
                              'lni-u-flex',
                              'lni-u-justify-between',
                              'lni-u-pv1',
                              'lni-u-ph1',
                              'lni-u-items-center']">
                    <span>
                        {{ link.label }}
                    </span>
                    <span
                        :aria-hidden="true"
                        :class="[ 'lni-c-link-list-arrow',
                                  'lni-u-ml1',
                                  'lnicon--triangle--right']"></span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: 'lni-link-list',

}; </script>